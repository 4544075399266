import axios from 'axios';
import router from '@/router';
import { updatecsrf } from '@/api/auth';

const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');


const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });

// 利用攔截器，將每個request 加上CSRF token
axiosInstance.interceptors.request.use(
  config => {
    // Laravel CSRF token
    const csrfToken = csrfTokenElement?.getAttribute('content');

    if (csrfToken) {
      config.headers['X-CSRF-TOKEN'] = csrfToken;
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 攔截回應
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {

    if (error.response) {

        switch (error.response.status) {
            case 401:
                router.push({ name: 'Login' });
                break;
            case 403:
                router.push({ name: 'Login' });
                break;
            case 404:
                router.push({ name: 'NotFound' });
                break;
            case 419: // CSRF token
                try {
                // 透過ajax 更新csrf token 並且更新meta
                    const newCsrfToken = await updatecsrf();
                    console.log('updatecsrf:',newCsrfToken);
                    if(newCsrfToken){
                        csrfTokenElement?.setAttribute('content',newCsrfToken);
                        error.config.headers['X-CSRF-TOKEN'] = newCsrfToken;
                        return axiosInstance.request(error.config);
                    }
                    return Promise.reject('refresh csrf token failed');
                } catch (refreshError) {
                    window.location.reload();
                    return Promise.reject(refreshError);
                }
            case 500:
                console.log("Server Error 500");
                break;
        }

        return Promise.reject(error.response.data.message || '服務器錯誤');
    }
    return Promise.reject(error || '發生錯誤');
  }
);

export default axiosInstance;
