import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import { createPinia } from 'pinia';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';
import BlockViewer from '@/components/BlockViewer.vue';

import Tooltip from 'primevue/tooltip';
import Toast from 'primevue/toast';

// import as directive
import BadgeDirective from 'primevue/badgedirective';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';

import 'primevue/resources/themes/saga-blue/theme.css';  // 主题
import 'primevue/resources/primevue.min.css';             // 核心CSS
import 'primeicons/primeicons.css';                       // 图标
import 'primeflex/primeflex.css';

import '@/assets/styles.scss';
import i18n from '@/lang';
import '@/utils/editorFix';

const pinia = createPinia()
const app = createApp(App);
app.use(router);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);
app.use(pinia);
app.use(i18n);

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);

app.component('BlockViewer', BlockViewer);
app.component('Toast', Toast);
app.mount('#app');
