import { defineStore } from 'pinia';
import { checkUser,loginApi,logoutApi,updatecsrf } from '@/api/auth';
import * as crypto from '@/utils/cryptotool.js';

const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');

export const useAuthStore = defineStore('auth', {
    state: () => ({
      isAuthenticated: false, // 用戶是否已經認證
      locale:'zh-TW',
      userinfo: {}, // 用戶的基本資訊
      secretkey:'',
      datakey:'',
      lastupdate: new Date()
    }),
    actions: {
        async login(credentials) {
            try {
                const data = await loginApi(credentials);
                this.setUser(data);
                return true;
            } catch (error) {
                throw error;
            }
        },
        async logout() {
            try {
                const data = await logoutApi();
                if(data)
                {
                    this.clearUser();
                    return true;
                }
            } catch (error) {
                throw error;
            }
        },
        async checkAuth() {
            try {
                const data = await checkUser();
                if(data)
                {
                    this.setUser(data);
                    return true;
                }
                else
                {
                    this.clearUser();
                    return false;
                }

            } catch (error) {
                this.clearUser();
                return false
            }
        },
        async updatecsrftoken() {
            try {
                const data = await updatecsrf();
                console.log('updatecsrf:',data);
                csrfTokenElement?.setAttribute('content',data);
                this.lastupdate = new Date();
            } catch (error) {
                this.clearUser();
                return false
            }
        },

        // 設置用戶信息，包括 token 和 userinfo
        setUser(data) {
            this.isAuthenticated = !!data;
            this.userinfo = data;
            this.datakey = `${data.name}_info`;
            this.secretkey = crypto.generatePassword(12);
            this.lastupdate = new Date();
            this.saveSessionData();
        },
        // 清除用戶信息，登出操作
        clearUser() {
            crypto.delencryptData(this.datakey);
            this.isAuthenticated = false;
            this.userinfo = {};
            this.locale = 'zh-TW';
            this.datakey = '';
            this.secretkey = '';
        },
        saveSessionData() {
            const data = {
                userinfo: this.userinfo,
                locale: this.locale
            };
            crypto.setencryptData(this.secretkey,this.datakey,data);
        },
        loadSessionData() {
            const data =  crypto.getencryptData(this.secretkey,this.datakey);
            if (data) {
                this.userinfo = data.userinfo;
                this.locale = data.locale;
                this.isAuthenticated = !!data.token;
            }
        },

    }
  });
