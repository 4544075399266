
<template>
  <router-view />
</template>
<script setup>
import { watch,onMounted } from 'vue';
import { useAuthStore } from '@/store/auth';
import { useMenuStore } from '@/store/menu';

const authStore = useAuthStore();
const menuStore = useMenuStore();

onMounted(async () => {
    await authStore.checkAuth();
    menuStore.defaultMenu();
})

/*
watch(
  () => menuStore.menus,
  (newMenus) => {
    // 重置路由
    const resetRouter = () => {
      const newRouter = createRouter({
        history: createWebHistory(),
        routes: initialRoutes
      });
      router.matcher = newRouter.matcher; // 重置路由匹配器
    };

    resetRouter();

    // 添加新的動態路由
    newMenus.forEach((menu) => {
      router.addRoute(menu);
    });
  },
  { deep: true, immediate: true }
);*/

// 監聽語言變化，更新 i18n 設定
watch(() => authStore.locale, (newLang) => {
    $i18n.locale= newLang;
});
</script>
<style scoped></style>
