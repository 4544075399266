import Editor from 'primevue/editor';

Editor.methods.renderValue = function renderValue(value) {
    console.log('renderValue');
  if (this.quill) {
    if (value) {
      const delta = this.quill.clipboard.convert({ html: value });
      this.quill.setContents(delta, 'silent');
    } else {
      this.quill.setText('');
    }
  }
};
