import CryptoJS from 'crypto-js';


// 加密数据函数
function encryptData(secretkey='your-defa',data) {

    const key = CryptoJS.enc.Utf8.parse(secretkey);
    const dataString = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(dataString, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}

// 解密数据函数
function decryptData(secretkey='your-defa',encryptedData) {
    const key = CryptoJS.enc.Utf8.parse(secretkey);
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}

export const  generatePassword = (length = 12)=> {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:",.<>?';
    let password = '';
    const values = new Uint8Array(length);
    window.crypto.getRandomValues(values);
    for (let i = 0; i < length; i++) {
        password += charset[values[i] % charset.length];
    }
    return password;
}

export const  delencryptData = (key)=> {
    if(key){
        sessionStorage.setItem(key,'');
    }
}

//  setencryptData 函数
export const setencryptData = (secretkey,datakey,data) => {
    if(data){
        const encryptedData = encryptData(secretkey,data);  //將資料加密
        sessionStorage.setItem(datakey, encryptedData);
    }

}

//  getencryptData 函数
export const getencryptData = (secretkey,datakey) => {
    if(key){
        const storedData = sessionStorage.getItem(datakey);
        if(storedData){
            const decodedData = decryptData(secretkey,storedData);
            return decodedData;
        }
    }
    return null;
}
