// api/auth.js

import $http  from '@/utils/request.js'; // 引入共用的 Axios 實例
import $api_router from './api_router.js';

// 檢查 token 是否有效
export async function checkUser() {
    try {
        const response = await $http.get($api_router.auth.checkuser);
        if (response.data.success) {
            return response.data.data;
        }
        else {
            throw new Error(response.data.message);
        }
    } catch (error) {
        return false;
    }
}

export async function updatecsrf() {
    try {
        const response = await $http.get($api_router.auth.csrf);
        if (response.data.csrf_token) {
            return response.data.csrf_token;
        }
        else {
            throw new Error(response.data.message);
        }
    } catch (error) {
        console.log('Error checking token validity:', error);
        return false;
    }
}


// 登入
export async function loginApi(data) {
    try {
        const response = await $http.post($api_router.auth.login, data);
        if (response.data.success) {
            return response.data.data;
        }
        else {
            throw response.data.message;
        }
    } catch (error) {
        throw new Error(error);
    }
}

// 登出
export async function logoutApi() {
    try {
        const response = await $http.get($api_router.auth.logout);
        if(response.data.success){
            return true;
        }

    } catch (error) {
        console.error('Error logging out:', error);
        throw new Error('登出失敗');
    }
}
