import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs'
import zhtw from "../locales/zh-TW.json";
import en from '../locales/en.json'


let i18n = createI18n({
    legacy: false,
    globalInjection: true, // 全局模式，可以直接使用 $t
    locale: 'zhtw',
    messages: {
        en,
        zhtw
    }
})
 
export default i18n